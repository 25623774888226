.toast {
  max-width: $toast-max-width;
  padding: 16px;
  overflow: hidden; // cheap rounded corners on nested items
  @include font-size($toast-font-size);
  color: $theme-white;
  background-clip: padding-box;
  background-color: $theme-primary;
  box-shadow: $toast-box-shadow;
  backdrop-filter: blur(10px);
  opacity: 0;
  @include border-radius($toast-border-radius);

  &:not(:last-child) {
    margin-bottom: $toast-padding-x;
  }

  &.showing {
    opacity: 1;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  &.hide {
    display: none;
  }
}

.bg-success .toast {
  background-color: $theme-primary;
}

.bg-danger .toast {
  background-color: $theme-danger;
}

.toast-header {
  display: flex;
  align-items: center;
  color: $theme-white;
  background-clip: padding-box;
}

.toast-body {
}
